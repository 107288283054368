.road-table-container table {
    border-collapse: collapse;
    border-color: #E2E2E3;
    border-width: 0.5px;
    font-size: 0.9rem;
}

.road-table-container th,
.road-table-container thead {
    position: sticky;
    top: 0;
    height: 70px;
    background-color: #F5F5F5;
}

.road-table-container th {
    margin-top: calc(-1*var(--medium));
    min-width: 140px;
}

.road-table-container tr td {
    padding: var(--small);
    max-width: 100px;
    height: 110px;
    word-wrap: break-word;
}

.road-table-container {
    width: 100%;
    height: 90vh;
    padding: var(--large);
    padding-right: 0;
    margin-top: var(--large);
    padding-top: 0;
    display: flex;
    flex-direction: row;
}

.first-table-container,
.second-table-container {
    /* width: 50%; */
    overflow-x: auto;
    overflow-y: auto;
    height: max-content;
    height: 90vh;
}

.first-table-container {
    max-width: 100%;
    margin-right: 10px;
    flex: 10;
}

.first-table-container::-webkit-scrollbar {
    display: block;
    width: 0px;
}

.second-table-container {
    overflow-x: hidden;
    width: 310px;
    /* flex: 1; */
}

.cur-forecast-col-header,
.cur-forecast-col-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    padding: 0 var(--huge);
}

.forecast-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.forecast-input {
    outline: none;
    border: 0.3px solid gray;
    width: 70px;
    padding: 5px;
    border-radius: var(--tiny);
}

.col-comment {
    min-width: 300px;
}
@import url("./SideBar.css");
@import url("./Loading.css");
@import url("./CustomTable.css");
@import url("./FilterList.css");
@import url("./CommentField.css");
@import url("./PageBody.css");
@import url("./FirecastTableHeader.css");
@import url("./antStyles.css");
@import url("./TableHeader.css");


.road-global-container {
    zoom: 80%;
}
.header_ {
    zoom: 90%;
}

.navigation-outer {
    padding: var(--large);
}

.navigation-right>* {
    margin: 0px var(--small);
}


.navigation-bar {
    margin-top: -20px;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    background-color: #EBEBEB;
}


.navigation-bar>li>a {
    width: 100%;
    padding: 15px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    text-transform: capitalize;
    transition: color 0.2s ease-in-out;
    text-decoration: none;
    color: black;
}

.navigation-bar>li>a.active {
    color: rgb(8, 133, 216);
}

.navigation-bar>li>a:hover {
    color: var(--main-color);
}

.navigation-bar>li>a>i {
    font-size: 1.5rem;
    margin-right: 10px;
}

.navigation-bar>li :hover {
    text-decoration: underline;
}
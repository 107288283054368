/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

body {
  margin: 0;
  font-family: "lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: small;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.row:focus {
  outline: none;
  background-color: red;
}

.helpbutton {
  position: absolute;
  top: 18px;
  right: 22%;
}

.data-child-modal {
  position: absolute;
  top: 20px;
  left: 219px;
}

/* table {
  border-collapse:collapse;
 } */

/* td {
  border:1px solid lightgray;
}
.table-div {
  max-width:35%;
  overflow-x:auto;
  border:1px solid black;

}
 .Table {
  background-color:transparent;
  min-width:20%;
  height:200px;
}

.Table thead,
.Table tbody {
  display: block;
}

.Table thead {
  border-bottom: 1px solid black;
}

.Table tbody {
  max-height: 200px;
  overflow-x: scroll;
}

.Table tr {
  display: flex;
}

.Table td,
.Table th {
  text-overflow: ellipsis;
  width: 100%;
  background-color: white;
}

.Table th {
  background-color: lightgray;
}

.Table tr:nth-child(2n) > td {
  background-color: #eeffee;
}

.Table th {
  border-left: 1px solid black;
}

.Table th:first-child {
  border-left: 0;
}

.Table th:nth-child(3) {
  min-width: 5%;
  background-color: aqua;
}

.Table td:nth-child(1) {
  min-width: 3px;
  background-color: aqua;
} */

.sticky-column {
  position: sticky;
  top: 0;
  background-color: white;
  border-bottom: 1px dotted #cccccc;
}

.container-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 200px;
  overflow-x: scroll;
  overflow-y: scroll;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.container-element {
  position: relative;
}

.data-child {
  position: absolute;
  top: 132px;
  left: 273px;
}

.detach {
  position: relative;
  bottom: 552px;
  left: 20px;
}

.header-name {
  font-size: medium;
  text-align: left;
  background-color: #f8f9fa;
  padding-left: 20px;
}

.header-label {
  font-size: small;
  padding-left: 20px;
}

.table-data {
  font-size: small;
  border: 1px solid #dddd !important;
  padding: 8px;
  text-align: left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.submit {
  position: relative;
  left: -48px;
  top: -23px;
}

.save {
  position: relative;
  left: -25px;
  top: -23px;
}

.enterduedate {
  position: relative;
  left: 100px;
  top: -23px;
}

.enterDuedate {
  border: 1px solid #dddd !important;
  background-color: #f8f9fa;
  position: relative;
  left: 0px;
  top: -23px;
}

.choosefile {
  position: relative;
  left: -20px;
  top: -23px;
}

.uploadbutton {
  position: relative;
  left: -100px;
  top: -23px;
}

.exportbutton {
  position: relative;
  left: -72px;
  top: -23px;
}

.navtext {
  font-weight: 600;
}

.navbarsticky {
  height: 30px;
  position: relative;
  bottom: 30px;
}

.columnhiding {
  position: relative;
  margin: 20px;
  border: 1px solid #dddd;
  padding: 8px;
  width: 225px;
  list-style-type: none;
  bottom: -17px;
}

.datashow {
  position: absolute;
  top: 117px;
  left: 300px;
}

.header {
  border-bottom: 1px solid #dddd;
}

.home {
  display: flex;
  justify-content: center;
  padding: 60px;
}

.headerTable {
  position: sticky;
  white-space: normal;
  font-size: small;
  width: calc(100% - 12px) !important;
  border: 1px solid #dddd;
  background-color: #f8f9fa;
  padding-right: 5px;
}

.containerInput {
  width: 81px;
}

.button {
  margin: 10px;
}

.signout {
  position: relative;
  left: -46px;
  top: 7px;
}

.ocean-text {
  position: relative;
  top: -54px;
}

.dupont {
  position: relative;
  right: 18px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-width: 1px !important;
}
.table-filter-icon.selected {
  border-top-color: red !important;
  box-shadow: inset 0 4px red !important;
  /* margin-left: 200px !important; */
}

.filter-list-item {
  z-index: 9 !important;
}

.filter-list {
  /* position: relative !important; */
  top: 45px !important;
  /*left: 70px !important;
  z-index: 90 !important; */
}

.sort-parent {
  z-index: 10 !important;
}
.air-outer {
    width: 100vw;
    height: 100vh;
    overflow: auto;
    box-shadow: 5px 10px #888888;
}

.air-columnhiding {
    margin-top: 107px;
}

.airleftpane {
    padding: var(--large);
}

.airleftpane>h5 {
    border-bottom: 1px solid lightgray;
}

.table-container-outer {
    -webkit-box-shadow: 0 0 10px 5px rgb(211, 211, 211);
    -moz-box-shadow: 0 0 10px 5px rgb(211, 211, 211);
    box-shadow: 0 0 10px 5px rgb(211, 211, 211);
    padding: var(--large);
    min-width: 100vw;
    max-height: 100vh;
}

.table-container {
    width: 100vw;
    min-height: 200px;
    max-height: 85vh;
    /* overflow: auto !important; */
    /* border: 1px solid lightgrey; */
    margin-top: var(--large);
}

.table-header {
    margin-top: -5vh;
}

/* .cur-forecast-col {
    width: 350px !important;
    position: -webkit-sticky;
    position: sticky;
    right: 0 !important;
    z-index: 11 !important;
} */

.cur-forecast-cell {
    /* position: sticky;
    position: -webkit-sticky;
    right: 0;
    z-index: 0; */
}

.cur-forecast-td {
    /* height: 100px; */
    background-color: white;
}

.cur-forecast-inputs {
    border: 1px solid lightgrey;
    border-radius: var(--tiny);
    width: 77;
}

.z-index-9 {
    z-index: 9;
}

.no-border {
    border: none;
}

.add-comment:hover {
    cursor: pointer;
}

.comments-col .comments-col>* {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.air-header {
    margin-top: calc(-2.5 * var(--huge));
    margin-bottom: calc(var(--huge));
    padding: var(--large) 0;

}

.air-header h4 {
    font-weight: 600;
    font-size: 26px;
}

.air-header h6 {
    margin-top: 24px;
    font-size: 18px;

}

th {
    /* background-color: red; */
    min-height: 300px;
}

/* .second-air-table {
    position: sticky;
    position: -webkit-sticky;
    right: 0;

} */

.air-container th {
    height: 90px;
}

.air-container td,
.table-data {
    background-color: white;
    height: 100px !important;
}

.headerTable {
    height: 70px;
}

.filter-list {
    z-index: 99 !important;
}

.date-picker ::placeholder {
    color: black !important;
}

.date-picker ::-ms-input-placeholder {
    color: black !important;
}

.first-table-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    margin-right: var(--small);
}

.first-table-container::-webkit-scrollbar {
    width: 0px;
}

.second-table-container {
    /*height: 85vh !important;*/
    overflow-y: auto;
    overflow-x: hidden;
}
.not-found {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70vh;
    color: gray;
}

.vline{
    
}
.error,
.vline {
    margin-right: var(--large);
    font-size: 2rem;

}
.home-images-container {
    width: 40vw;
    display: flex;
    justify-content: space-between;
    align-self: center;
    margin-left: 30vw;
    margin-top: 5vh;
}

.home-image-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 10px 10px 10px rgb(216, 216, 216);
    font-weight: bold;

}

.home-image-div:hover {
    color: black;
    cursor: pointer;
    font-size: 1.1rem;
}

.home-image {
    /* height: 200px; */
    height: 12vw;
}
:root {
    /* sizes */
    --tiny: 6px;
    --small: 10px;
    --medium: 10px;
    --large: 14px;
    --huge: 18px;

}

.active {
    color: #1890FF !important;
}

.active :hover {
    color: #1890FF !important;
}

.gray-text {
    color: gray;
}

.blue-text {
    color: #23a9e2;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-start {
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-20 {
    flex: 20%;
}

.flex-30 {
    flex: 30%;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.space-evenly {
    justify-content: space-evenly;
}

.padding-x-medium {
    padding: var(--medium);
}

.padding-y-medium {
    padding: var(--medium);
}

.large-padding {
    padding: var(--large);
}

.padding-large {
    padding: var(--large);
}



.padding-medium {
    padding: var(--medium);
}

.bold {
    font-weight: bold;
}

.br {
    background-color: red;
}

.white-background {
    background-color: white;
}

.block {
    display: block;
}

.width-100 {
    width: 100%;
}

.width-10 {
    width: 10%;
}

.width-15 {
    width: 15%;
}

.width-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.height-100 {
    height: 100%;
}

.max-width-100 {
    max-width: 100%;
}

.max-height-100 {
    max-height: 100%;
}

.overflow {
    overflow: hidden;
}

.mr-medium {
    margin-right: var(--medium);
}

.mr-huge {
    margin-right: var(--huge);
}

.ml-medium {
    margin-left: var(--medium);
}

.ml-small {
    margin-left: var(--small);
}

.mb-huge {
    margin-bottom: var(--huge);
}

.mb-medium {
    margin-bottom: var(--medium);
}

.mb-small {
    margin-bottom: var(--small);
}

.opacity-8 {
    opacity: .8;
}

.float-left {
    float: left;
}

.z-index-9 {
    z-index: 9;
}

.z-index--1 {
    z-index: -1;
}

.z-index--2 {
    z-index: -2;
}

.box-shadow {
    box-shadow: 10px 10px 10px rgb(137, 137, 137);
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #a0a0a0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #696969;
}

/* @media screen and (max-width:1500px) { */
.zoom-90 {
    zoom: 90%;
}

.zoom-80 {
    zoom: 80%;
}

.text-decoration-none {
    text-decoration: none;
}

/* } */